/* eslint-disable no-param-reassign */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FaSimCard, FaSpinner } from 'react-icons/fa';
import { FiMessageSquare, FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import SSApi from '../../services/api/smartsim.api';

import Input from '../../components/Input';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';
// import Notification from '../../components/Notification';
import Header from '../../components/Header';
import CharCounter from '../../components/CharCounter';

import { Container, Content, AnimationContainer, Notification } from './styles';

interface SMSFormData {
  phone: string;
  message: string;
}

interface Notification {
  id: number;
  situacao?: string;
  codigo?: number;
  data_envio: Date;
  operadora: string;
  descricao: string;
  mensagem: string;
  telefone: string;
}

const Dashboard: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const [credits, setCredits] = useState(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  const [loading, setLoading] = useState(false);
  const [char, setChar] = useState('');
  // const [ringStyle, setRingStyle] = useState({
  //   stroke: '',
  //   strokeDasharray: '',
  // });

  const { addToast } = useToast();

  async function loadCredits(): Promise<void> {
    const balanceResponse = await SSApi.get('user-profile/balance');

    setCredits(balanceResponse.data.balance);
  }

  async function loadNotifications(): Promise<void> {
    const response = await SSApi.get('sms/status', {
      params: { page: Number(page) },
    });

    setPageLimit(response.data.totalMessages);

    if (response.data.messageStatus === []) {
      setNotifications([]);

      return;
    }

    if (user.sms_key === null) {
      setNotifications([]);
      return;
    }

    setNotifications(response.data.messageStatus);
  }

  const handleMinusPage = useCallback(() => {
    if (page <= 1) {
      return;
    }

    setPage(page - 1);
  }, [page]);

  const handlePlusPage = useCallback(() => {
    if (page * 5 > pageLimit) {
      return;
    }
    setPage(page + 1);
  }, [page, pageLimit]);

  const handleLength = useCallback((): boolean => {
    if (char.length > 160) {
      return true;
    }
    return false;
  }, [char]);

  useEffect(() => {
    loadCredits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSubmit = useCallback(
    async (data: SMSFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          phone: Yup.string()
            .required('Telefone obrigatório')
            .min(11, 'Digite um numero de telefone válido')
            .matches(/[0-9]+/, 'Apenas números são válidos'),
          message: Yup.string().required('Mensagem obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { phone, message } = data;

        const response = await SSApi.post('sms/send', {
          destination: String(phone),
          content: String(message),
        });

        const { codigo } = response.data;

        if (codigo === '400') {
          addToast({
            type: 'error',
            title: 'Erro no envio',
            description: 'Texto ou URL da mensagem não definida.',
          });

          throw new Error('Saldo insuficiente');
        }

        if (codigo === '401') {
          addToast({
            type: 'error',
            title: 'Erro no envio',
            description: 'Texto ou URL da mensagem não definida.',
          });

          throw new Error('Saldo insuficiente');
        }

        if (codigo === '402') {
          addToast({
            type: 'error',
            title: 'Erro no envio',
            description: 'Campo number sem valor definido.',
          });

          throw new Error('Saldo insuficiente');
        }

        if (codigo === '403') {
          addToast({
            type: 'error',
            title: 'Erro no envio',
            description:
              'Você precisa abrir uma conta conosco para começar a enviar. Entre em contato conosco assim que possível.',
          });

          throw new Error('Saldo insuficiente');
        }

        if (codigo === '407') {
          addToast({
            type: 'error',
            title: 'Erro no envio',
            description:
              'Erro na validação do numero. Verifique o número de telefone informado.',
          });

          throw new Error('Saldo insuficiente');
        }

        if (codigo === '408') {
          addToast({
            type: 'error',
            title: 'Erro no envio',
            description:
              'Saldo insuficiente. Efetue uma recarga de créditos assim que possível.',
          });

          throw new Error('Saldo insuficiente');
        }

        if (codigo === '414') {
          addToast({
            type: 'error',
            title: 'Erro no envio',
            description:
              'Conta não confirmada ou bloqueada. Favor entre em contato com seu vendedor.',
          });

          throw new Error('Saldo insuficiente');
        }

        if (codigo === '420') {
          addToast({
            type: 'error',
            title: 'Erro no envio',
            description:
              'Número de caracteres máximo atingido. Não esqueça o limite de 160.',
          });

          throw new Error('Saldo insuficiente');
        }

        setLoading(false);

        addToast({
          type: 'success',
          title: 'Mensagem enviada!',
          description: 'Verifique se o dispositivo recebeu a mensagem.',
        });

        loadCredits();
        loadNotifications();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro no envio',
            description:
              'Ocorreu um erro ao enviar sua mensagem, cheque os campos e alertas.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast, user.sms_key],
  );

  return (
    <Container>
      <Header credits={credits} />
      <Content>
        <AnimationContainer>
          <div>
            <h1>Envie seu SMS</h1>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                name="phone"
                icon={FaSimCard}
                placeholder="(99) 99999-9999"
              />
              <TextArea
                name="message"
                icon={FiMessageSquare}
                placeholder="Mensagem"
                onChange={e => setChar(e.target.value)}
              />
              {char.length > 0 ? <CharCounter counter={char.length} /> : ''}
              <Button
                loading={loading}
                icon={FaSpinner}
                type="submit"
                length={handleLength()}
              >
                Enviar
              </Button>
            </Form>
          </div>
          <aside>
            <h1>Últimas mensagens enviadas</h1>

            {notifications.length === 0 && (
              <p>Nenhuma mensagem enviada ainda</p>
            )}
            {notifications.map(notification => (
              <Notification key={notification.id}>
                <div className="fields">
                  <strong>Data/Hora</strong>
                  <strong>Status</strong>
                  <strong>Operadora</strong>
                  <strong>Mensagem</strong>
                  <strong>Destino</strong>
                </div>
                <div className="values">
                  <p>{notification.data_envio}</p>
                  <p>{notification.descricao}</p>
                  <p>{notification.operadora}</p>
                  <p>{notification.mensagem}</p>
                  <p>{notification.telefone}</p>
                </div>
              </Notification>
            ))}
            {notifications.length === 0 ? (
              ''
            ) : (
              <div className="pagination">
                {page === 1 ? (
                  <button type="button" disabled style={{ display: 'none' }}>
                    <FiArrowLeft
                      size={20}
                      color="#00cc00"
                      style={{ marginRight: 10 }}
                    />
                  </button>
                ) : (
                  <button type="button" onClick={() => handleMinusPage()}>
                    <FiArrowLeft
                      size={20}
                      color="#00cc00"
                      style={{ marginRight: 10 }}
                    />
                  </button>
                )}

                <p>{page}</p>

                {notifications.length < 5 || page * 5 >= pageLimit ? (
                  <button type="button" disabled style={{ display: 'none' }}>
                    <FiArrowRight
                      size={20}
                      color="#00cc00"
                      style={{ marginLeft: 10 }}
                    />
                  </button>
                ) : (
                  <button type="button" onClick={() => handlePlusPage()}>
                    <FiArrowRight
                      size={20}
                      color="#00cc00"
                      style={{ marginLeft: 10 }}
                    />
                  </button>
                )}
              </div>
            )}
          </aside>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Dashboard;
