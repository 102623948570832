import React from 'react';
import { FaUsersCog, FaUsers } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Container, Content, AnimationContainer, AdminOption } from './styles';

const AdminOptions: React.FC = () => {
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Link to="update-sms-config">
            <AdminOption>
              <FaUsersCog size={40} color="#00cc00" />
              <h1>Alterar configurações de SMS</h1>
            </AdminOption>
          </Link>
          <Link to="users">
            <AdminOption>
              <FaUsers size={40} color="#00cc00" />
              <h1>Listar Usuários</h1>
            </AdminOption>
          </Link>
          <Link to="/dashboard" className="back">
            <FiArrowLeft />
            Voltar para dashboard
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default AdminOptions;
