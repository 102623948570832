import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import AdminRoute from './AdminRoute';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Inbox from '../pages/Inbox';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Import from '../pages/Import';
import Shop from '../pages/Shop';

import UpdateUser from '../pages/Admin/UpdateUser';
import AdminOptions from '../pages/Admin/AdminOptions';
import Users from '../pages/Admin/Users';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" exact component={SignUp} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/reset-password" exact component={ResetPassword} />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/profile" exact component={Profile} isPrivate />
      <Route path="/inbox" exact component={Inbox} isPrivate />
      <Route path="/import" exact component={Import} isPrivate />
      <Route path="/shop" exact component={Shop} isPrivate />

      <AdminRoute
        path="/admin-options"
        exact
        component={AdminOptions}
        isAdmin
      />
      <AdminRoute
        path="/update-sms-config"
        exact
        component={UpdateUser}
        isAdmin
      />
      <AdminRoute path="/users" exact component={Users} isAdmin />
    </Switch>
  );
};

export default Routes;
