import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  > header {
    height: 144px;
    background: #28262e;

    display: flex;
    align-items: center;

    div {
      width: 100%;
      max-width: 1220px;
      margin: 0 auto;
      z-index: 1;

      svg {
        color: #999591;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: -90px auto 50px auto;
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  max-width: 1220px;
  height: 60%;

  animation: ${appearFromTop} 1.5s;

  h1 {
    text-align: center;
    margin-bottom: 80px;
  }
`;

export const TableHeader = styled.div`
  width: 1220px;
  height: 60px;
  padding: 13px 5px 13px 13px;
  border-radius: 10px;
  background: #232129;
  color: #00cc00;
  margin-bottom: 8px;
  display: flex;
  justify-items: start;
  align-items: center;

  position: relative;

  .item-1 {
    max-width: 20px;
    margin-right: 25px;
  }

  .item-2 {
    max-width: 50px;
    flex: 1;
    margin-right: 14px;
  }

  .item-3 {
    flex: 1;
    max-width: 250px;
    text-align: start;
  }

  .item-4 {
    flex: 1;
    max-width: 150px;
    text-align: center;
  }

  .item-5 {
    margin-left: 20px;
    flex: 1;
    max-width: 100px;
    text-align: center;
  }

  .item-6 {
    margin-left: 20px;
    flex: 1;
    max-width: 400px;
    text-align: start;
    margin-right: 20px;
  }

  .item-7 {
    padding-left: 20px;
  }

  :after {
    content: '';
    background: #00cc00;
    position: absolute;
    bottom: 9.5%;
    left: 0;
    height: 80%;
    width: 4px;
  }
`;

const moveOnHover = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(10px);
  }
`;

export const TableRow = styled.div`
  width: 1220px;
  height: 60px;
  padding: 13px 5px 13px 13px;
  border-radius: 10px;
  background: #232129;
  margin-bottom: 8px;
  display: flex;

  align-items: center;

  position: relative;
  transition: color 1s;

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 20px;
  }

  .item-1 {
    max-width: 20px;
    margin-right: 20px;
  }

  .item-3 {
    flex: 1;
    max-width: 250px;
    text-align: start;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item-4 {
    flex: 1;
    max-width: 150px;
    text-align: center;
  }

  .item-5 {
    margin-left: 20px;
    flex: 1;
    max-width: 100px;
    text-align: center;
  }

  .item-6 {
    margin-left: 20px;
    flex: 1;
    max-width: 500px;
    text-align: start;
  }

  .item-7 {
    flex: 1;
    max-width: 120px;
    display: flex;
    align-items: center;

    > button {
      margin-right: 5px;
    }

    button {
      background: transparent;
      border: 0;
    }

    svg {
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &:hover {
    animation: ${moveOnHover} 0.5s forwards;
    background: ${shade(0.2, '#232129')};
  }

  :after {
    content: '';
    background: #00cc00;
    position: absolute;
    bottom: 9.5%;
    left: 0;
    height: 80%;
    width: 4px;
  }
`;
