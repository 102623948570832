import React from 'react';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import productImg from '../../assets/sms-sim.jpg';

import { Container, Content, AnimationContainer, ProductsRow } from './styles';
import Button from '../../components/Button';

const Shop: React.FC = () => {
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h1>Selecione seu plano</h1>
          <ProductsRow>
            <div>
              <h1>Pacote 1</h1>
              <img src={productImg} alt="product-1" />
              <p>100 SMS</p>
              <p>R$21,90</p>
              <a
                href="https://www.mercadopago.com.br/checkout/v1/redirect?pref_id=189786795-1ee09488-4a49-48c2-9c6f-82ba580840b5"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>Comprar agora</Button>
              </a>
            </div>
            <div>
              <h1>Pacote 2</h1>
              <img src={productImg} alt="product-2" />
              <p>400 SMS</p>
              <p>R$65,90</p>
              <a
                href="https://www.mercadopago.com.br/checkout/v1/redirect?pref_id=189786795-926433ae-2c70-438e-9e2d-da0d0952c4ba"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>Comprar agora</Button>
              </a>
            </div>
            <div>
              <h1>Pacote 3</h1>
              <img src={productImg} alt="product-3" />
              <p>1000 SMS</p>
              <p>R$109,90</p>
              <a
                href="https://www.mercadopago.com.br/checkout/v1/redirect?pref_id=189786795-8c1ef622-c20b-40b3-8407-b1d0fb29c20e"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>Comprar agora</Button>
              </a>
            </div>
            <div>
              <h1>Pacote 4</h1>
              <img src={productImg} alt="product-4" />
              <p>2500 SMS</p>
              <p>R$231,90</p>
              <a
                href="https://www.mercadopago.com.br/checkout/v1/redirect?pref_id=189786795-c2c3c8b3-9366-4e76-a986-a6f1a5e8163d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>Comprar agora</Button>
              </a>
            </div>
          </ProductsRow>
          <Link to="/dashboard">
            <FiArrowLeft />
            Voltar ao dashboard
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Shop;
