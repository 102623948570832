import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromTop} 1.5s;

  a {
    text-decoration: none;
  }

  a.back {
    color: #00cc00;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#f4ede8')};
    }

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }
  }
`;

const moveOnHover = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(20px);
  }
`;

export const AdminOption = styled.div`
  /* border-left: 5px solid #00cc00; */
  width: 600px;
  height: 100px;
  padding: 13px 5px 13px 13px;
  border-radius: 10px;
  background: #232129;
  color: #fff;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  position: relative;
  transition: color 1s;

  &:hover {
    animation: ${moveOnHover} 1.5s forwards;
    background: ${shade(0.2, '#232129')};
  }

  svg {
    margin-left: 20px;
  }

  h1 {
    margin-left: 20px;
  }

  :after {
    content: '';
    background: #00cc00;
    position: absolute;
    bottom: 9.5%;
    left: 0;
    height: 80%;
    width: 4px;
  }

  @media (max-width: 840px) {
    align-items: center;
    display: flex;
    width: 340px;
    font-size: 10px;

    svg {
      margin-left: 10px;
    }

    h1 {
      margin-left: 20px;
    }
  }
`;
