import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import { FiArrowLeft } from 'react-icons/fi';

import SSApi from '../../../services/api/smartsim.api';

import {
  Container,
  Content,
  AnimationContainer,
  TableHeader,
  TableRow,
} from './styles';

interface SMSUsers {
  sms_refer: number;
  sms_credits: number;
  name: string;
  email: string;
  avatar: string;
  admin?: boolean;
}

const Users: React.FC = () => {
  const [smsUsers, setSmsUsers] = useState<SMSUsers[]>([]);
  // const [page, setPage] = useState(1);

  async function loadUsers(): Promise<void> {
    setSmsUsers([]);

    const response = await SSApi.get('users');

    if (response.data === []) {
      setSmsUsers([]);
      return;
    }

    setSmsUsers(response.data);
  }

  // const handleMinusPage = useCallback(() => {
  //   if (page <= 1) {
  //     return;
  //   }

  //   setPage(page - 1);
  // }, [page]);

  // const handlePlusPage = useCallback(() => {
  //   setPage(page + 1);
  // }, [page]);

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <header>
        <div>
          <Link to="/admin-options">
            <FiArrowLeft size={24} color="#999591" />
          </Link>
        </div>
      </header>
      <Content>
        <AnimationContainer>
          <h1>Usuários</h1>
          <TableHeader>
            <h4 className="item-1">#</h4>
            <h4 className="item-2">Avatar</h4>
            <h4 className="item-3">Name</h4>
            <h4 className="item-4">Credits</h4>
            <h4 className="item-5">Refer</h4>
            <h4 className="item-6">E-mail</h4>
            <h4 className="item-7">Opções</h4>
          </TableHeader>

          {smsUsers.map((smsUser, index) => (
            <TableRow>
              <h4 className="item-1">
                {index < 9 ? `0${index + 1}` : index + 1}
              </h4>
              <img
                src="https://unipublicabrasil.com.br/curso-gratuito/views/assets/img/avatar/homem-1.png"
                alt="avatar"
              />
              <h4 className="item-3">{smsUser.name}</h4>
              <h4 className="item-4">{smsUser.sms_credits}</h4>
              <h4 className="item-5">{smsUser.sms_refer}</h4>
              <h4 className="item-6">{smsUser.email}</h4>
              <div className="item-7">
                <button type="button">
                  <FaEdit size={20} color="#00cc00" />
                </button>
                <button type="button">
                  <MdDeleteForever size={25} color="#c53030" />
                </button>
              </div>
            </TableRow>
          ))}
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Users;
