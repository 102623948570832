import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';
// import SMSDevApi from '../../services/api/sms.api';
import SSApi from '../../services/api/smartsim.api';

import { Container, Content, AnimationContainer, Notification } from './styles';

interface InboxMessage {
  situacao: string;
  data_read: Date;
  telefone: number;
  id: string;
  refer_id: string;
  msg_sent: string;
  id_sms_read: number;
  descricao: string;
}

const Inbox: React.FC = () => {
  const [inbox, setInbox] = useState<InboxMessage[]>([]);
  const [page, setPage] = useState(1);

  const { user } = useAuth();

  async function loadInbox(): Promise<void> {
    setInbox([]);

    const response = await SSApi.get('sms/inbox', {
      params: { page, status: 1 },
    });

    if (response.data === []) {
      setInbox([]);
      return;
    }

    if (user.sms_key === null) {
      setInbox([]);
      return;
    }

    if (response.data.descricao === 'SEM MENSAGENS NA CAIXA DE ENTRADA.') {
      setInbox([]);
      return;
    }

    setInbox(response.data);
  }

  const handleMinusPage = useCallback(() => {
    if (page <= 1) {
      return;
    }

    setPage(page - 1);
  }, [page]);

  const handlePlusPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    loadInbox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Container>
      <header>
        <div>
          <Link to="/dashboard">
            <FiArrowLeft />
          </Link>
        </div>
      </header>
      <Content>
        <AnimationContainer>
          <div>
            <h1>Mensagens Recebidas</h1>
            {inbox.length === 0 && <h2>Nenhuma mensagem recebida ainda</h2>}

            {inbox.map(message => (
              <Notification key={message.id_sms_read}>
                <div className="fields">
                  <strong>Situação:</strong>
                  <strong>Telefone:</strong>
                  <strong>Enviada:</strong>
                  <strong>Respondida:</strong>
                  <strong>Data:</strong>
                </div>
                <div className="values">
                  <p>{message.situacao}</p>
                  <p>{message.telefone}</p>
                  <p>{message.msg_sent}</p>
                  <p>{message.descricao}</p>
                  <p>{message.data_read}</p>
                </div>
              </Notification>
            ))}
            {inbox.length === 0 ? (
              ''
            ) : (
              <div className="pagination">
                {page === 1 ? (
                  <button type="button" disabled>
                    <FiArrowLeft
                      size={20}
                      color="#00cc00"
                      style={{ marginRight: 10 }}
                    />
                  </button>
                ) : (
                  <button type="button" onClick={() => handleMinusPage()}>
                    <FiArrowLeft
                      size={20}
                      color="#00cc00"
                      style={{ marginRight: 10 }}
                    />
                  </button>
                )}

                <p>{page}</p>

                {inbox.length < 10 ? (
                  <button type="button" disabled>
                    <FiArrowRight
                      size={20}
                      color="#00cc00"
                      style={{ marginLeft: 10 }}
                    />
                  </button>
                ) : (
                  <button type="button" onClick={() => handlePlusPage()}>
                    <FiArrowRight
                      size={20}
                      color="#00cc00"
                      style={{ marginLeft: 10 }}
                    />
                  </button>
                )}
              </div>
            )}
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Inbox;
