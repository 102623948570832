import React, { useRef, useCallback, useState } from 'react';
import { FiMail, FiArrowLeft } from 'react-icons/fi';
import { FaSpinner, FaUserCircle, FaKey } from 'react-icons/fa';
import { GiTwoCoins } from 'react-icons/gi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import SSApi from '../../../services/api/smartsim.api';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Container, Content, AnimationContainer } from './styles';

interface SMSUpdateFormData {
  email: string;
  sms_key?: string;
  refer?: string;
  credits?: number;
}

const _UpdateUser: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SMSUpdateFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          sms_key: Yup.string(),
          refer: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { email, sms_key, refer, credits } = data;

        await SSApi.put('/users', {
          email,
          sms_key,
          refer,
          credits,
        });

        history.push('/dashboard');

        addToast({
          type: 'success',
          title: 'Perfil do cliente atualizado!',
          description: 'O cliente já pode começar a enviar mensagens.',
        });

        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description:
            'Ocorreu um erro ao atualizar seu perfil, tente novamente',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Cadastro de funcionalidades SMS</h1>

            <Input name="email" icon={FiMail} placeholder="E-mail do cliente" />

            <Input
              name="sms_key"
              icon={FaKey}
              type="sms_key"
              placeholder="SMS Key"
            />
            <Input
              name="refer"
              icon={FaUserCircle}
              type="refer"
              placeholder="Número de referencia"
            />
            <Input
              name="credits"
              icon={GiTwoCoins}
              type="credits"
              placeholder="Créditos"
            />

            <Button loading={loading} icon={FaSpinner} type="submit">
              Cadastrar Chave
            </Button>
          </Form>

          <Link to="/admin-options">
            <FiArrowLeft />
            Voltar para opções
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default _UpdateUser;
