import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px 0;
  background: #000;
`;

export const HeaderContent = styled.div`
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  > img {
    height: 80px;
  }

  @media (max-width: 840px) {
    > div {
      margin-left: 10px;
    }

    h1 {
      display: none;
    }

    button {
      margin-right: 20px;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #f4ede8;
    }

    strong {
      max-width: 120px;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      text-decoration: none;
      color: #00cc00;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #f4ede8;
    }

    a {
      text-decoration: none;
      color: #00cc00;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Buttons = styled.div`
  margin-left: auto;

  button {
    background: transparent;
    border: 0;
    margin-right: 10px;

    &:hover {
      opacity: 0.8;
    }

    svg {
      color: #999591;
    }
  }

  .admin-button {
    margin-right: 20px;
  }

  .inbox-button {
    margin-right: 20px;
  }
`;
