import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiAlertTriangle } from 'react-icons/fi';
import filesize from 'filesize';

import Upload from '../../components/Upload';
import FileList from '../../components/FileList';
import { useToast } from '../../hooks/toast';

import SSApi from '../../services/api/smartsim.api';

import {
  Container,
  Content,
  AnimationContainer,
  ImportFileContainer,
  Footer,
} from './styles';

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

const Import: React.FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);

  const { addToast } = useToast();

  const history = useHistory();

  async function handleUpload(): Promise<void> {
    const data = new FormData();

    if (!uploadedFiles.length) return;

    const file = uploadedFiles[0];

    data.append('file', file.file, file.name);

    try {
      const response = await SSApi.post('sms/import', data);

      const { codigo } = response.data;

      if (!codigo) {
        addToast({
          type: response.data.type,
          title: response.data.title,
          description: response.data.description,
        });
      }

      if (codigo === '408') {
        addToast({
          type: 'error',
          title: 'Erro no envio',
          description:
            'Saldo insuficiente. Efetue uma recarga de créditos assim que possível.',
        });

        throw new Error('Saldo insuficiente');
      }

      history.push('/');
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no envio',
        description:
          'Ocorreu um erro ao enviar sua mensagem, cheque os campos.',
      });
    }
  }

  function submitFile(files: File[]): void {
    const upload = files.map(file => ({
      file,
      name: file.name,
      readableSize: filesize(file.size),
    }));

    setUploadedFiles(upload);
  }
  return (
    <Container>
      <header>
        <div>
          <Link to="/dashboard">
            <FiArrowLeft />
          </Link>
        </div>
      </header>
      <Content>
        <AnimationContainer>
          <div>
            <h1>Envio em massa</h1>
            <ImportFileContainer>
              <Upload onUpload={submitFile} />
              {!!uploadedFiles.length && <FileList files={uploadedFiles} />}

              <Footer>
                <FiAlertTriangle color="#969cb3" />
                <p> Permitido apenas arquivos CSV </p>
                <button onClick={handleUpload} type="button">
                  Enviar
                </button>
              </Footer>
            </ImportFileContainer>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Import;
