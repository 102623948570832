import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  > header {
    height: 144px;
    background: #28262e;

    display: flex;
    align-items: center;

    div {
      width: 100%;
      max-width: 1120px;
      margin: 0 auto;

      svg {
        color: #999591;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: -90px auto 0;
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  width: 100%;
  width: 600px;
  margin-bottom: 20px;

  > div {
    width: 100%;

    > h2 {
      text-align: center;
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 80px;
  }

  animation: ${appearFromTop} 1.5s;

  @media (max-width: 840px) {
    max-width: 340px;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    button {
      background: none;
      border: 0;
    }
  }
`;

export const Notification = styled.div`
  /* border-left: 5px solid #00cc00; */
  width: 100%;
  padding: 13px 5px 13px 20px;
  border-radius: 10px;
  background: #232129;
  margin-bottom: 8px;
  display: flex;
  position: relative;

  .fields {
    display: flex;
    flex-direction: column;
    max-width: 90px;
    justify-content: space-around;
    margin-right: 30px;

    strong {
      color: #00cc00;
    }
  }

  .values {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    p {
      font-size: 14px;
    }
  }

  :after {
    content: '';
    background: #00cc00;
    position: absolute;
    bottom: 6.5%;
    left: 0;
    height: 88%;
    width: 4px;
  }

  h5 {
    color: #00cc00;
    margin-bottom: 5px;
  }
`;
