import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 35px;
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1120px;
  justify-content: space-around;

  @media (max-width: 840px) {
    flex-direction: column;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      form {
        width: 340px;
      }

      button {
        width: 340px;
      }
    }

    aside {
      h1 {
        max-width: 360px;
        margin: 40px 0 40px 0;
      }

      max-width: 340px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 500;
    }
  }

  animation: ${appearFromTop} 1.5s;

  form {
    margin: 40px 0 80px 0;
    width: 400px;
    text-align: center;
  }

  > a {
    color: #00cc00;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#f4ede8')};
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  aside {
    h1 {
      max-width: 360px;
      margin-bottom: 40px;
    }

    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    button {
      background: none;
      border: 0;
    }
  }
`;

export const Notification = styled.div`
  /* border-left: 5px solid #00cc00; */
  width: 100%;
  padding: 13px 5px 13px 13px;
  border-radius: 10px;
  background: #232129;
  margin-bottom: 8px;
  display: flex;
  position: relative;

  .fields {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 10px;

    strong {
      color: #00cc00;
      font-size: 13px;
    }
  }

  .values {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    p {
      font-size: 13px;
    }

    p:nth-child(4) {
      width: 380px;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  :after {
    content: '';
    background: #00cc00;
    position: absolute;
    bottom: 9.5%;
    left: 0;
    height: 80%;
    width: 4px;
  }

  h5 {
    color: #00cc00;
    margin-bottom: 5px;
  }

  @media (max-width: 840px) {
    .values {
      p:nth-child(4) {
        width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: '#312E38';
  background-size: cover;
`;
