import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin: auto;

  @media (max-width: 840px) {
    height: 100%;
  }
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromTop} 1.5s;

  > h1 {
    margin-top: 60px;
  }

  > a {
    color: #00cc00;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#f4ede8')};
    }

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }
  }

  @media (max-width: 840px) {
    margin-bottom: 30px;
  }
`;

const moveOnHover = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20px);
  }
`;

export const ProductsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    height: 600px;
    width: 300px;
    margin: 30px 10px;
    padding: 10px;
    border-radius: 10px;
    background: #28262e;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      animation: ${moveOnHover} 1s forwards;
    }

    a {
      width: 100%;
    }

    h1 {
      margin-top: 20px;
      margin-bottom: 50px;
    }

    p {
      margin-top: 30px;
      font-size: 20px;
      font-weight: bold;
    }

    p + p {
      font-weight: 300;
      font-size: 50px;
    }

    img {
      width: 250px;
      height: 250px;
      border-radius: 10px;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
    }

    button {
      margin-top: 10px;
    }
  }

  @media (max-width: 840px) {
    flex-direction: column;
  }
`;
