import React from 'react';
import { Link } from 'react-router-dom';
import { FiPower } from 'react-icons/fi';

import {
  FaUsersCog,
  FaInbox,
  FaFileUpload,
  FaShoppingCart,
} from 'react-icons/fa';
import { useAuth } from '../../hooks/auth';

import { Container, HeaderContent, Profile, NavItem, Buttons } from './styles';

const Header: React.FC<Credits> = ({ credits }) => {
  const { admin, user, signOut } = useAuth();

  return (
    <Container>
      <HeaderContent>
        <Profile>
          <img
            src={
              user.avatar_url
                ? user.avatar_url
                : 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTOcCR9Qg_yFyOD4CA6ITWOGJM02y9JqwwLLA&usqp=CAU'
            }
            alt={user.name}
          />

          <div>
            <span>Bem-vindo,</span>
            <Link to="/profile">
              <strong className="user-name">{user.name}</strong>
            </Link>
          </div>
        </Profile>
        <NavItem>
          <div>
            <span>Créditos</span>
            {credits === undefined || 0 ? (
              <Link to="/shop">
                <strong>Adquira créditos agora!</strong>
              </Link>
            ) : (
              <Link to="/shop">
                <strong>
                  Você tem
                  {` ${credits} `}
                  créditos
                </strong>
              </Link>
            )}
          </div>
        </NavItem>

        <Buttons>
          {admin && (
            <button type="button" className="admin-button">
              <Link to="/admin-options">
                <FaUsersCog size={25} color="#00cc00" />
              </Link>
            </button>
          )}
          <button type="button" className="inbox-button">
            <Link to="/shop">
              <FaShoppingCart size={20} color="#00cc00" />
            </Link>
          </button>
          <button type="button" className="inbox-button">
            <Link to="/import">
              <FaFileUpload size={20} color="#00cc00" />
            </Link>
          </button>
          <button type="button" className="inbox-button">
            <Link to="/inbox">
              <FaInbox size={20} color="#00cc00" />
            </Link>
          </button>
          <button type="button" onClick={signOut}>
            <FiPower size={20} />
          </button>
        </Buttons>
      </HeaderContent>
    </Container>
  );
};

export default Header;
